import style from "./password.module.scss";
import { useState } from "react";

export default function PasswordInput() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("password");
  const [password, setPassword] = useState<string>("");

  function handleToggle() {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  }
  return (
    <>
      <label>Password</label>
      <input
        className={style.passwordInput}
        type={inputType}
        value={password}
        placeholder="password"
        onChange={(e) => setPassword(e.target.value)}
        required
      />

      <span onClick={handleToggle} className="show-toggle">
        {showPassword ? (
          <i className="fa-solid fa-eye-slash"></i>
        ) : (
          <i className="fa-solid fa-eye"></i>
        )}
      </span>
    </>
  );
}
