import React, { useEffect, useState } from "react";
import styles from "./communicate.module.scss";
import { handleComs, getComms, deleteComms } from "../../features/Profile";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import { toast } from "sonner";
import { AppLoader } from "../../components/AppLoader";
import { ClipLoader, RingLoader } from "react-spinners";
const Communicate = () => {
  const queryClient = useQueryClient();
  const [com, setCom] = useState({
    toolName: "",
    toolUrl: "",
  });

  interface CommData {
    id: string;
    toolName: string;
    toolUrl: string;
  }
  const [commData, setCommData] = useState<CommData[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCom({ ...com, [name]: value });
  };

  const { mutateAsync: setComsMutation, isPending: pending } = useMutation({
    mutationFn: handleComs,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getComms"] });
      toast.success(`${com?.toolName} has been added Successfully`, {
        richColors: true,
      });
    },
    onError: (error) => {
      toast.error(error.message ?? "Something went wrong", {
        richColors: true,
      });
    },
  });

  const {
    isPending,
    isError,
    data: commsData,
    error,
  } = useQuery({
    queryKey: ["getComms"],
    queryFn: getComms,
  });

  useEffect(() => {
    setCommData(commsData?.data?.result);
  }, [commsData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setComsMutation(com);
  };

  const { mutateAsync: deleteComs, isPending: deletePending } = useMutation({
    mutationFn: deleteComms,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getComms"] });
      toast.success(`comunication tool has been deleted Successfully`, {
        richColors: true,
      });
    },
    onError: (error) => {
      toast.error(error.message ?? "Something went wrong", {
        richColors: true,
      });
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(`${error}` || "something went wrong", {
        richColors: true,
      });
    }
  }, [isError]);

  return (
    <div className={styles.comCont}>
      <div className={styles.communicate}>
        <p className={styles.header}>Communication Tools</p>

        <div className={styles.textBody}>
          <div>
            <h3>Select a Communication tool</h3>
            <p>The selected tool for communication would be used for all the lesson sessions</p>
          </div>

          <form className={styles.formm} onSubmit={handleSubmit}>
            <div className={styles.picker}>
              <select
                className={styles.selection}
                name="toolName"
                value={com["toolName"]}
                onChange={handleChange}
              >
                <option>Select an Option</option>
                <option value="zoom">Zoom</option>
                <option value="google_meet">Google Meet</option>
                <option value="microsoft_teams">Teams</option>
              </select>

              <input
                type="text"
                placeholder="Enter meeting Link"
                className={styles.field}
                name="toolUrl"
                value={com["toolUrl"]}
                onChange={handleChange}
              />
            </div>
            <div className={styles.btnBox}>
              <Button variant="secondary" isLoading={pending} disabled={pending}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.selected}>
        {deletePending && (
          <div className={styles.SelectH1}>
            <ClipLoader />
          </div>
        )}
        <div className={styles.list}>
          {isPending ? (
            <p>Loading...</p>
          ) : commData?.length > 0 ? (
            <div className={styles.listBox}>
              {commData.map((item, index) => (
                <div key={index} className={styles.comBox}>
                  <div>
                    {item.toolName === "google_meet" ? (
                      <Icon name="meet" height={30} />
                    ) : item.toolName === "zoom" ? (
                      <Icon name="Zoom" height={30} />
                    ) : (
                      item.toolName === "microsoft_teams" && <Icon name="teams" height={30} />
                    )}
                  </div>
                  <div className={styles.id}>
                    {item.toolName === "google_meet" ? (
                      <p> Google meet </p>
                    ) : item.toolName === "zoom" ? (
                      <p>Zoom</p>
                    ) : (
                      item.toolName === "microsoft_teams" && <p>Microsoft Teams</p>
                    )}

                    <p>{item.toolUrl}</p>
                  </div>
                  <div
                    className={styles.close}
                    onClick={() => {
                      deleteComs(item.id);
                    }}
                  >
                    <Icon name="close" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>You haven't added any communication tools</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Communicate;
