import React, { useState } from "react";
import Modal from "react-modal";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    width: "65%",
    paddingHorizontal: "20px",
    paddingVertical: "24px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    borderColor: "white",
  },
};

const inputStyle = {
  width: "80%",
  padding: "10px",
  marginBottom: "10px",
  border: "1px solid #ccc",
  borderRadius: "9px",
  fontSize: "16px",
};

const buttonStyle = {
  padding: "10px 15px",
  marginTop: "10px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
};

const addPackageButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#28a745",
  marginRight: "10px",
};

const removePackageButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#dc3545",
};

export const LessonModal = (props: any) => {
  // State for form inputs
  const [lessonName, setLessonName] = useState("");
  const [description, setDescription] = useState("");
  const [minPrice, setMinPrice] = useState<any>("");
  const [packages, setPackages] = useState<any>([
    { price: "", duration: 30, quantity: "" },
  ]);
  const [language, setLanguage] = useState("");
  const [values, setValues] = useState<any>({
    data: [],
    error: "",
    loading: false,
    submitError: "",
  });

  const clearHandler = () => {
    setLessonName("");
    setDescription("");
    setMinPrice("");
    setPackages([{ price: "", duration: 30, quantity: "" }]);
    setLanguage("");
    setValues({
      // Corrected reset
      data: [],
      error: "",
      loading: false,
      submitError: "",
    });
  };
  // Custom validation function
  const validate = () => {
    const newErrors: any = {};

    if (!lessonName?.trim()) newErrors.lessonName = "Lesson Name is required.";
    if (!description.trim()) newErrors.description = "Description is required.";
    if (!minPrice || parseFloat(minPrice) <= 0)
      newErrors.minPrice = "Min Price must be a positive number.";
    if (parseFloat(minPrice) < 8)
      newErrors.minPrice = "Min Price must be more than 8 (USD)";
    if (parseFloat(minPrice) > 70)
      newErrors.minPrice = "Min Price must not be more than 70 (USD)";
    if (!language) newErrors.language = "Please select a language."; // Validation for language dropdown

    packages.forEach((pkg: any, index: any) => {
      if (!pkg.price || parseFloat(pkg.price) <= 0)
        newErrors[`packagePrice_${index}`] = "Price must be a positive number.";
      if (!pkg.duration)
        newErrors[`packageDuration_${index}`] = "Please select a duration.";
      if (!pkg.quantity || parseInt(pkg.quantity) <= 0)
        newErrors[`packageQuantity_${index}`] =
          "Quantity must be a positive integer.";
    });

    setValues({ ...values, error: newErrors });
    return Object.keys(newErrors).length === 0;
  };

  // Add a new package
  const addPackage = () => {
    setPackages([...packages, { price: "", duration: 30, quantity: "" }]);
  };

  // Remove a package by index
  const removePackage = (index: number) => {
    if (index === 0) return;
    const updatedPackages = packages.filter(
      (_: any, pkgIndex: any) => pkgIndex !== index
    );
    setPackages(updatedPackages);
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validate()) {
      const url = "https://pluri-api-service.onrender.com/lessons/create";

      setValues({ ...values, loading: true, error: "", submitError: "" });
      const token = await localStorage.getItem("token");

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: lessonName,
            languageId: parseInt(language),
            description,
            minPrice: parseInt(minPrice),
            packages,
          }),
        });

        const result = await response.json();
        console.log(result.message);
        if (!result.error) {
          props.closeModal();
          clearHandler();
          props.fetchLessons();
          setValues({
            ...values,
            data: result.result,
            loading: false,
            submitError: "",
          });
        } else {
          setValues({
            ...values,
            loading: false,
            submitError: result.message ?? "Something went wrong",
          });
        }
      } catch (err: any) {
        console.log(err);

        setValues({
          ...values,
          loading: false,
          submitError: err.message ?? "Something went wrong",
        });
      }
    }
  };

  // Handle package input change
  const handlePackageChange = (
    index: number,
    field: "price" | "duration" | "quantity",
    value: number
  ) => {
    const updatedPackages = [...packages];
    updatedPackages[index][field] = value;
    setPackages(updatedPackages);
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Lesson Modal"
      overlayClassName="modal-backdrop"
    >
      <form onSubmit={handleSubmit}>
        <div className="form">
          <div className="sideA">
            <p className="lessonTitle">Lesson Details</p>
            <label className="label">
              Lesson Title
              <p className="inputSubtitle">
                The Name of the lesson eg. Introductory Igbo Lesson
              </p>
              <input
                placeholder="Lesson Title"
                type="text"
                value={lessonName}
                onChange={(e) => setLessonName(e.target.value)}
                style={inputStyle}
              />
              {values.error.lessonName && (
                <p style={errorStyle}>{values.error.lessonName}</p>
              )}
            </label>
            <br />
            <label className="label">
              Lesson Description
              <p className="inputSubtitle">
                What do students expect to learn from this lesson
              </p>
              <textarea
                placeholder="Lesson Description"
                className="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={inputStyle}
              />
              {values.error.description && (
                <p style={errorStyle}>{values.error.description}</p>
              )}
            </label>
            <br />
            <label className="label">
              Select Language
              <p className="inputSubtitle">
                What language does this lesson fall under
              </p>
              <select
                value={language}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
                style={inputStyle}
              >
                <option value="" disabled>
                  Select Language
                </option>
                {props.languages?.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {values.error.language && (
                <p style={errorStyle}>{values.error.language}</p>
              )}
            </label>
            <br />
            <label className="label">
              Minimum Price (USD)
              <p className="inputSubtitle">
                The cheapest package plan available for this lesson
              </p>
              <input
                placeholder="e.g 30"
                type="number"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                style={inputStyle}
              />
              {values.error.minPrice && (
                <p style={errorStyle}>{values.error.minPrice}</p>
              )}
            </label>
          </div>

          <div className="sideB">
            <p className="lessonTitle">Add Lesson Packages</p>
            {packages.map((pkg: any, index: any) => (
              <div key={index}>
                <div className="sideBContainer">
                  <label className="label">
                    Price <br />
                    <input
                      placeholder="e.g 4"
                      type="number"
                      value={pkg.price}
                      onChange={(e) =>
                        handlePackageChange(
                          index,
                          "price",
                          parseInt(e.target.value)
                        )
                      }
                      // style={inputStyle}
                      className="packageInput"
                    />
                    {values.error[`packagePrice_${index}`] && (
                      <p style={errorStyle}>
                        {values.error[`packagePrice_${index}`]}
                      </p>
                    )}
                  </label>

                  <label className="label">
                    Duration
                    <select
                      className="packageInput"
                      value={pkg.duration}
                      onChange={(e) =>
                        handlePackageChange(
                          index,
                          "duration",
                          parseInt(e.target.value)
                        )
                      }
                      // style={inputStyle}
                    >
                      <option value={30}>30 minutes</option>
                      <option value={60}>60 minutes</option>
                    </select>
                    {values.error[`packageDuration_${index}`] && (
                      <p style={errorStyle}>
                        {values.error[`packageDuration_${index}`]}
                      </p>
                    )}
                  </label>
                  <label className="label">
                    Quantity
                    <input
                      placeholder="e.g 30"
                      className="packageInput"
                      type="number"
                      value={pkg.quantity}
                      onChange={(e) =>
                        handlePackageChange(
                          index,
                          "quantity",
                          parseInt(e.target.value)
                        )
                      }
                    />
                    {values.error[`packageQuantity_${index}`] && (
                      <p style={errorStyle}>
                        {values.error[`packageQuantity_${index}`]}
                      </p>
                    )}
                  </label>
                  {index === 0 ? (
                    <FontAwesomeIcon
                      className="deleteIcon"
                      icon={["fas", "trash"]}
                      onClick={() => removePackage(index)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="deleteIcon"
                      icon={["fas", "trash"]}
                      onClick={() => removePackage(index)}
                    />
                  )}
                </div>
              </div>
            ))}
            <div className="removePackageButtonContainer">
              <button
                type="button"
                onClick={addPackage}
                // onClick={() => removePackage(index)}
                className="removePackageButton"
              >
                + add package
              </button>
            </div>
          </div>
        </div>
        <div className="buttonsContainer">
          <button
            type="button"
            onClick={() => clearHandler()}
            className="addPackageButtonStyle"
          >
            Discard
          </button>

          <button type="submit" className="buttonStyle">
            {values.loading ? <span className="loader"></span> : "Save"}
          </button>
        </div>
        {values.submitError && <p style={errorStyle}>{values.submitError}</p>}
      </form>
    </Modal>
  );
};
