import { IconPropTypes } from ".";

const copy = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.59995 13.3996L2.19995 13.3996C1.53721 13.3996 0.999951 12.8624 0.999951 12.1996L0.999953 3.39961C0.999954 2.29504 1.89538 1.39961 2.99995 1.39961L11.8 1.39961C12.4627 1.39961 13 1.93687 13 2.59961L13 4.99961M10.5999 20.5996L17.8 20.5996C19.1254 20.5996 20.2 19.5251 20.2 18.1996L20.2 10.9996C20.2 9.67413 19.1254 8.59961 17.8 8.59961L10.6 8.59961C9.27447 8.59961 8.19995 9.67413 8.19995 10.9996L8.19995 18.1996C8.19995 19.5251 9.27447 20.5996 10.5999 20.5996Z"
      stroke="#169FED"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const zoom = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9_2649)">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V16C24 20.4183 20.4183 24 16 24H8C3.58172 24 0 20.4183 0 16V8Z"
        fill="#3984FD"
      />
      <path
        d="M5 9C5 8.44772 5.44772 8 6 8H12C13.6569 8 15 9.34315 15 11V15C15 15.5523 14.5523 16 14 16H8C6.34315 16 5 14.6569 5 13V9Z"
        fill="white"
      />
      <path
        d="M15.5 11.751C15.5 11.2666 15.6758 10.7986 15.9948 10.434L17.6856 8.50177C18.1416 7.98059 19 8.30312 19 8.99564V15.0035C19 15.696 18.1416 16.0185 17.6856 15.4973L15.9948 13.5651C15.6758 13.2005 15.5 12.7325 15.5 12.2481V11.751Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_2649">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const spin = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 16}
    height={height ?? 16}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"
    >
      <animateTransform
        attributeName="transform"
        dur="0.6s"
        repeatCount="indefinite"
        type="rotate"
        values="0 12 12;360 12 12"
      ></animateTransform>
    </path>
  </svg>
);

const menu = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth="2"
      d="M21.6 19.2H2.4M21.6 12H2.4m19.2-7.2H2.4"
    ></path>
  </svg>
);

const close = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
  >
    <path
      fill="#000"
      d="m14.199 1.917-1.35-1.35L7.493 5.92 2.14.566.79 1.917l5.353 5.354L.79 12.626l1.35 1.35 5.355-5.354 5.354 5.354 1.35-1.35L8.846 7.27z"
    ></path>
  </svg>
);

const left_arrow = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 7H4.33L9.92 1.41L8.5 0L0.5 8L8.5 16L9.91 14.59L4.33 9H16.5V7Z" fill="black" />
  </svg>
);
const updown = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="10px"
    width="18"
    height="18"
  >
    <path
      fillRule="evenodd"
      d="M11.47 4.72a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 0 1-1.06-1.06l3.75-3.75Zm-3.75 9.75a.75.75 0 0 1 1.06 0L12 17.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75 0 0 1 0-1.06Z"
      clipRule="evenodd"
    />
  </svg>
);
const meet = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 48 48"
  >
    <rect width="16" height="16" x="12" y="16" fill="#fff" transform="rotate(-90 20 24)"></rect>
    <polygon fill="#1e88e5" points="3,17 3,31 8,32 13,31 13,17 8,16"></polygon>
    <path fill="#4caf50" d="M37,24v14c0,1.657-1.343,3-3,3H13l-1-5l1-5h14v-7l5-1L37,24z"></path>
    <path fill="#fbc02d" d="M37,10v14H27v-7H13l-1-5l1-5h21C35.657,7,37,8.343,37,10z"></path>
    <path fill="#1565c0" d="M13,31v10H6c-1.657,0-3-1.343-3-3v-7H13z"></path>
    <polygon fill="#e53935" points="13,7 13,17 3,17"></polygon>
    <polygon fill="#2e7d32" points="38,24 37,32.45 27,24 37,15.55"></polygon>
    <path
      fill="#4caf50"
      d="M46,10.11v27.78c0,0.84-0.98,1.31-1.63,0.78L37,32.45v-16.9l7.37-6.22C45.02,8.8,46,9.27,46,10.11z"
    ></path>
  </svg>
);
const Zoom = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="24" r="20" fill="#2196f3"></circle>
    <path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z"></path>
    <polygon fill="#fff" points="37,31 31,27 31,21 37,17"></polygon>
  </svg>
);

const teams = ({ width, height, ...props }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 48 48"
  >
    <path
      fill="#5059c9"
      d="M44,22v8c0,3.314-2.686,6-6,6s-6-2.686-6-6V20h10C43.105,20,44,20.895,44,22z M38,16	c2.209,0,4-1.791,4-4c0-2.209-1.791-4-4-4s-4,1.791-4,4C34,14.209,35.791,16,38,16z"
    ></path>
    <path
      fill="#7b83eb"
      d="M35,22v11c0,5.743-4.841,10.356-10.666,9.978C19.019,42.634,15,37.983,15,32.657V20h18	C34.105,20,35,20.895,35,22z M25,17c3.314,0,6-2.686,6-6s-2.686-6-6-6s-6,2.686-6,6S21.686,17,25,17z"
    ></path>
    <circle cx="25" cy="11" r="6" fill="#7b83eb"></circle>
    <path
      d="M26,33.319V20H15v12.657c0,1.534,0.343,3.008,0.944,4.343h6.374C24.352,37,26,35.352,26,33.319z"
      opacity=".05"
    ></path>
    <path
      d="M15,20v12.657c0,1.16,0.201,2.284,0.554,3.343h6.658c1.724,0,3.121-1.397,3.121-3.121V20H15z"
      opacity=".07"
    ></path>
    <path
      d="M24.667,20H15v12.657c0,0.802,0.101,1.584,0.274,2.343h6.832c1.414,0,2.56-1.146,2.56-2.56V20z"
      opacity=".09"
    ></path>
    <linearGradient
      id="DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1"
      x1="4.648"
      x2="23.403"
      y1="14.648"
      y2="33.403"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#5961c3"></stop>
      <stop offset="1" stop-color="#3a41ac"></stop>
    </linearGradient>
    <path
      fill="url(#DqqEodsTc8fO7iIkpib~Na_zQ92KI7XjZgR_gr1)"
      d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"
    ></path>
    <path fill="#fff" d="M18.068,18.999H9.932v1.72h3.047v8.28h2.042v-8.28h3.047V18.999z"></path>
  </svg>
);

export type IconType = keyof typeof Icons;

const Icons = { copy, zoom, spin, menu, close, left_arrow, updown, meet, Zoom, teams };
export default Icons;
