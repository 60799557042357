import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import style from "./forgot_password.module.scss";
import { PasswordInput } from "../../components";

export default function PasswordReset() {
  const [password, setPassword] = useState<string>("");
  const [reEnterPassword, setReEnterPassword] = useState<string>("");

  const [error, setError] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showSecondPassword, setShowSecondPassword] = useState<boolean>(false);

  const [inputType, setInputType] = useState<string>("password");
  const [secondInputType, setSecondInputType] = useState<string>("password");

  const [SearchParams] = useSearchParams();
  const token = SearchParams.get("token");
  const email = SearchParams.get("email");

  const navigate = useNavigate();

  function handleToggle() {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  }

  function handleSecondToggle() {
    setShowSecondPassword(!showSecondPassword);
    setSecondInputType(showSecondPassword ? "password" : "text");
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const api = "https://api.pluritongues.com";

    if (password !== reEnterPassword) {
      setError("Password is not the same");
      return;
    }

    setError("");
    setLoading(true);

    const url = `${api}/auth/reset-password-merchant`;
    const body = { token, email, password };
    console.log(url, body);
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.result);
        navigate("/login");
      } else {
        setError("check your password and try again");
      }
    } catch (err) {
      setError("Something went wrong, try again! " + err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="layout">
      <div className="password-reset-wrapper side-bar">
        <h1>Password Reset.</h1>
        <p>Create a new password</p>

        <form onSubmit={handleSubmit} className="form-wrapper">
          <label>New Password</label>
          <input
            className="password-input"
            type={inputType}
            value={password}
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />

          <span onClick={handleToggle} className="show-toggle">
            {showPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </span>

          {/* <label>Re-enter New Password</label>
          <input
            className="second-password-input"
            type={secondInputType}
            value={reEnterPassword}
            placeholder="password"
            onChange={(e) => setReEnterPassword(e.target.value)}
          />
          <span onClick={handleSecondToggle} className="show-second-toggle">
            {showSecondPassword ? (
              <i className="fa-solid fa-eye-slash"></i>
            ) : (
              <i className="fa-solid fa-eye"></i>
            )}
          </span> */}

          <PasswordInput />

          {error && <div className="error">{error}</div>}

          <button type="submit" className="button">
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </form>

        <p className="footer-password-reset">
          &copy; right <a href="https://pluritongues.com">Pluritongues</a>. All
          rights reserved
        </p>
      </div>

      <div className="main-wrapper"></div>
    </div>
  );
}
