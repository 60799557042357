import React, { useState } from "react";
import { StepProps } from "../types";
import { API_URL } from "../../../utils/constants";
import { toast } from "sonner";
import Icon from "../../../components/Icon";
import axios, { AxiosResponse } from "axios";

const Step4: React.FC<StepProps> = ({ setCurrentStep, selectedItem, values, updateValues }) => {
  const [text, setText] = useState(selectedItem.details.payoneerId);

  const getAuthToken = () => localStorage.getItem("token");

  // centralized the Axios config aswell
  const getAxiosConfig = (token: string | null) => ({
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const updatePayID = async () => {
    const token = getAuthToken();

    updateValues({ ...values, loading: true, error: "" });
    const url = `${API_URL}/payment-method/${selectedItem.id}`;

    const raw = JSON.stringify({
      details: {
        payoneerId: text,
      },
    });

    try {
      const response: AxiosResponse = await axios.patch(url, raw, getAxiosConfig(token));

      if (response) {
        toast.success("Payoneer ID has been updated successfully", {
          richColors: true,
        });
        updateValues({
          ...values,
          loading: false,
        });
        setCurrentStep(1);
      } else {
        const errorMessage = "Something went wrong";
        toast.error(errorMessage, { richColors: true });

        updateValues({
          ...values,
          loading: false,
          error: errorMessage,
        });
      }
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : "An unknown error occurred";
      console.error(err);

      toast.error(errorMessage, { richColors: true });
      updateValues({
        ...values,
        loading: false,
        error: errorMessage,
      });
    }
  };

  return (
    <div>
      <div onClick={() => setCurrentStep(1)} className="walletContentContainer2">
        <Icon name="left_arrow" />
        <p className="wallet-title2">Set up Payoneer</p>
      </div>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <div className="contentContainer3">
            <p className="payLabel">Edit Your Payoneer ID</p>
            <br />
            <input
              onChange={(e) => setText(e.target.value)}
              defaultValue={selectedItem.details.payoneerId}
              className="payInput"
              placeholder="payoneer ID"
            />
          </div>

          <button onClick={() => updatePayID()} className="doneButton">
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default Step4;
