import React from "react";
import { StepProps } from "../types";
import Icon from "../../../components/Icon";

const Step2: React.FC<StepProps> = ({ setCurrentStep, setSelectedItem, selectedItem }) => {
  return (
    <div>
      <div onClick={() => setCurrentStep(1)} className="walletContentContainer2">
        <Icon name="left_arrow" />
        <p className="wallet-title2">Add withdrawal method</p>
      </div>
      <p className="title">Select where and how you want to receive your earnings.</p>
      <div className="payoneerContainer2">
        <div>
          <p className="payoneer">Payoneer</p>
          <p className="payoText">
            Payoneer charges additional fees to withdraw funds. Don't have a 
            <a rel="noopener" target="_blank" href="https://www.payoneer.com/" className="link">
              Payoneer account
            </a>
            ?
          </p>
        </div>
        <button onClick={() => setCurrentStep(3)} className="setUp">
          Set up
        </button>
      </div>
    </div>
  );
};

export default Step2;
