import React, { useState } from "react";
import styles from "./history.module.scss";
import Table, { column } from "../../components/Table";
import { status } from "./types";
import { transactionHistory } from "./types";

const Paymenthistory = () => {
  interface tableData {
    amount: number;
    Request: string;
    Transaction: transactionHistory;
    status: status;
  }

  const data: tableData[] = [
    {
      amount: 600,
      Request: "May 20, 2015",
      Transaction: transactionHistory.Withdraw,
      status: status.paid,
    },
    {
      amount: 3000,
      Request: "May 20, 2015",
      Transaction: transactionHistory.earning,
      status: status.paid,
    },
    {
      amount: 40,
      Request: "Nov 23, 2015",
      Transaction: transactionHistory.Withdraw,
      status: status.pending,
    },
    {
      amount: 900,
      Request: "July 10, 2015",
      Transaction: transactionHistory.Payout,
      status: status.failed,
    },
    {
      amount: 1200,
      Request: "Feb 20, 2015",
      Transaction: transactionHistory.earning,
      status: status.paid,
    },
  ];

  const column: column<tableData>[] = [
    {
      header: "Amount",
      id: "amount",
    },
    {
      header: "Request Date",
      id: "Request",
    },
    {
      header: "Transaction Type",
      id: "Transaction",
    },
    {
      header: "Status",
      id: "status",
    },
  ];

  const [search, setSearch] = useState<string>("");
  return (
    <div className={styles.hbody}>
      <div className={styles.bdxt}>
        <p className={styles.payHistory}>Payment History</p>
        <div className={styles.analytics}>
          <div className={styles.total}>
            <p>Total Earnings</p>
            <h1>$6,000</h1>
          </div>
          <div className={styles.boxss}>
            <div className={styles.taw}>
              <p>Total Amount Withdrawn</p>
              <h2>$5,000</h2>
            </div>

            <div className={styles.taw}>
              <p>Total Withdrawal Request</p>
              <h2>20</h2>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tablesection}>
        <p className={styles.trHead}>Transaction List</p>
        <input
          type="text"
          placeholder="Search by Request date"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
        />
        <Table data={data} columns={column} search={search} />
      </div>
    </div>
  );
};

export default Paymenthistory;
