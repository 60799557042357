export interface StepProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  selectedItem: PaymentMethod;
  values: Values;
  updateValues: React.Dispatch<React.SetStateAction<Values>>;
}

export interface PaymentMethod {
  id: string;
  type: string;
  accountEnding: string;
  isPrimary: boolean;
  details: {
    payoneerId: string;
  };
}

export interface Values {
  data: PaymentMethod[];
  loading: boolean;
  error: string;
}


export enum status {
  paid = "Paid",
  pending = "Pending",
  failed = "Failed",
}

export enum transactionHistory {
  Withdraw = "Withdrawal",
  earning = "Earning",
  Payout = "Payout",
}
