import axios from "axios";
import { API_URL } from "../utils/constants";
interface comsBody {
  toolName: string;
  toolUrl: string;
}

const getToken = () => {
  return localStorage.getItem("token");
};
let token = getToken;

const getMerchantId = () => {
  return localStorage.getItem("id");
};

//add the comunication tools of choice
export const handleComs = async (body: comsBody) => {
  let token = getToken();
  const response = await axios.post(`${API_URL}/teachers/communication-tools`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const getComms = async () => {
  let merchantId = getMerchantId();
  let token = getToken();
  const response = await axios.get(`${API_URL}/teachers/communication-tools/${merchantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteComms = async (id: string) => {
  let token = getToken();
  const response = await axios.delete(`${API_URL}/teachers/communication-tools/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
