import React, { useState } from "react";
import "./walletStyles.scss";
import "react-toggle/style.css";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import { Values } from "./types";

export const WalletSettings: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const [selectedItem, setSelectedItem] = useState({
    id: "",
    type: "",
    accountEnding: "",
    isPrimary: false,
    details: {
      payoneerId: "",
    },
  });

  const [values, updateValues] = useState<Values>({
    data: [],
    loading: false,
    error: "",
  });


  const steps: { [key: number]: JSX.Element } = {
    1: (
      <Step1
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        values={values}
        updateValues={updateValues}
      />
    ),
    2: (
      <Step2
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        values={values}
        updateValues={updateValues}
      />
    ),
    3: (
      <Step3
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        values={values}
        updateValues={updateValues}
      />
    ),
    4: (
      <Step4
        setCurrentStep={setCurrentStep}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        values={values}
        updateValues={updateValues}
      />
    ),
  };

  return <div className="walletContainer">{steps[currentStep]}</div>;
};
