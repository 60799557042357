// This the card that introduces the counts
import React from "react";
import "./card.scss";

interface CardProps {
  totalTransactions: number;
  isLoading: boolean;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  totalTransactions,
  isLoading,
  onClick,
}) => {
  return (
    <div
      className="transactions-card"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div className="card-content">
        <h3>Upcoming Classes</h3>
        {isLoading && <p>Loading...</p>}
        <p className="transaction-count">{totalTransactions}</p>
      </div>
    </div>
  );
};

export default Card;
