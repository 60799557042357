import { useState, useEffect, useCallback } from "react";

export interface Message {
  content: string;
  createdAt: string;
  isRead?: boolean;
  senderUserType: string;
  receiver: string;
  sender?: string;
  id?: string;
  status?: "pending" | "sent" | "failed";
}

const fetchMessages = async (id: string) => {
  const token = await localStorage.getItem("accessToken");
  try {
    const response = await fetch(
      `https://pluri-api-service.onrender.com/messages/${id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      console.log("Failed to fetch messages");
    }
    const data = await response.json();

    return data.result?.sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt || 0).getTime();
      const dateB = new Date(b.createdAt || 0).getTime();
      return dateB - dateA;
    });
  } catch (error) {
    console.warn(error);
  }
};

export const useMessages = () => {
  const [messages, setMessages] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchChatList = useCallback((id: any) => {
    setLoading(true);
    fetchMessages(id)
      .then((msgs: any) => {
        setMessages(msgs);
        setError(null);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  const addMessage = (newMessage: any) =>
    setMessages((prev: any) => [newMessage, ...prev]);

  const updateMessageStatus = (id: string, status: "sent" | "failed") => {
    setMessages((prev: any) =>
      prev.map((msg: any) =>
        msg.transaction === id ? { ...msg, status } : msg
      )
    );
  };

  return {
    messages,
    loading,
    error,
    addMessage,
    updateMessageStatus,
    fetchChatList,
  };
};
