import React, { useEffect, useState } from "react";
import styles from "../styles/table.module.scss";
import Icon from "./Icon";

export interface column<T> {
  header: string;
  id: keyof T;
}
interface tableProps<T> {
  data: T[];
  columns: column<T>[];
  search: string;
}
const Table = <T extends { status: string; Request: string; amount: number; Transaction: string }>({
  data,
  columns,
  search,
}: tableProps<T>) => {
  const [filteredData, setFilteredData] = useState<Array<T>>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T | null;
    direction: "ascending" | "descending" | null;
  }>({ key: null, direction: null });

  useEffect(() => {
    let updatedData = data.filter((item) =>
      item.Request.toLowerCase().includes(search.toLocaleLowerCase())
    );

    if (sortConfig.key && sortConfig.direction) {
      updatedData.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof T];
        const bValue = b[sortConfig.key as keyof T];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }
        return 0;
      });
    }

    setFilteredData(updatedData);
  }, [data, search, sortConfig]);

  const handleSort = (key: keyof T) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (sortConfig.key === key && sortConfig.direction === "descending") {
      direction = "ascending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className={styles.tableBody}>
      <table className={styles.tables}>
        <thead>
          <tr>
            {columns?.map((head, index) => (
              <th key={index} className={styles.headRow} onClick={(e) => handleSort(head.id)}>
                <div className={styles.headbox}>
                  <p>{head.header}</p>

                  <Icon name="updown" />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((item, index) => (
            <tr key={index}>
              {columns?.map((colItem, colIndex) => (
                <td
                  key={colIndex}
                  className={`${
                    colItem.id === "status"
                      ? item.status.toLocaleLowerCase() === "paid"
                        ? styles.green
                        : item.status.toLowerCase() === "pending"
                        ? styles.yellow
                        : item.status.toLowerCase() === "failed"
                        ? styles.red
                        : ""
                      : ""
                  } ${styles.tablebody}`}
                >
                  {String(item[colItem.id])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.mobileTable}>
        {filteredData?.map((item, index) => (
          <div className={styles.boxTab} key={index}>
            <div className={styles.box}>
              <p>Amount</p>
              <p>{item.amount}</p>
            </div>
            <div
              className={`${styles.box2} ${
                item.status.toLocaleLowerCase() === "paid"
                  ? styles.green
                  : item.status.toLowerCase() === "pending"
                  ? styles.yellow
                  : item.status.toLowerCase() === "failed"
                  ? styles.red
                  : ""
              }`}
            >
              <p>Status</p>
              <p>{item.status}</p>
            </div>
            <div className={styles.box}>
              <p>Request Date</p>
              <p>{item.Request}</p>
            </div>
            <div className={styles.box2}>
              <p>Transaction type</p>
              <p>{item.Transaction}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
