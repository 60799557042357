import React, { useState } from "react";
import { toast } from "sonner";
import { PaymentMethod, StepProps } from "../types";
import { API_URL } from "../../../utils/constants";
import Toggle from "react-toggle";
import Icon from "../../../components/Icon";
import axios, { AxiosResponse } from "axios";

const Step3: React.FC<StepProps> = ({
  setCurrentStep,
  setSelectedItem,
  selectedItem,
  values,
  updateValues,
}) => {
  const [text, setText] = useState<string>("");
  const [makeDefault, setMakeDefault] = useState<boolean>(false);

  const getAuthToken = () => localStorage.getItem("token");

  // centralized the Axios config aswell
  const getAxiosConfig = (token: string | null) => ({
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const uploadPayID = async () => {
    const token = getAuthToken();
    updateValues({ ...values, loading: true, error: "" });
    const url = `${API_URL}/payment-method`;

    if (!text) {
      toast.error("Could not create payment method with invalide payment Id", {
        richColors: true,
      });
      updateValues({
        ...values,
        loading: false,
        error: "",
      });
    } else if (text) {
      const raw = JSON.stringify({
        type: "payoneer",
        details: {
          payoneerId: text,
          isPrimary: makeDefault,
        },
      });

      try {
        const response: AxiosResponse = await axios.post<PaymentMethod>(
          url,
          raw,
          getAxiosConfig(token)
        );

        const result = response.data;

        if (response) {
          updateValues({
            ...values,
            data: [result],
            loading: false,
          });
          toast.success("Account added successfully", { richColors: true });
          setCurrentStep(1);
        } else {
          const errorMessage = "Something went wrong";
          toast.error(errorMessage, { richColors: true });

          updateValues({
            ...values,
            loading: false,
            error: errorMessage,
          });
        }
      } catch (err: unknown) {
        const errorMessage = err instanceof Error ? err.message : "An unknown error occurred";
        console.error(err);

        toast.error(errorMessage, { richColors: true });
        updateValues({
          ...values,
          loading: false,
          error: errorMessage,
        });
      }
    }
  };

  return (
    <div>
      <div onClick={() => setCurrentStep(2)} className="walletContentContainer2">
        <Icon name="left_arrow" />
        <p className="wallet-title2">Set up Payoneer</p>
      </div>
      {values.loading ? (
        <p>Loading</p>
      ) : (
        <div>
          <div className="contentContainer3">
            <p className="payLabel">Enter Your Payoneer ID</p>
            <br />
            <input
              onChange={(e) => setText(e.target.value)}
              className="payInput"
              placeholder="payoneer ID"
            />
          </div>
          <div className="makeDefault">
            <span className="defaultText">Make default</span>
            <Toggle
              defaultChecked={makeDefault}
              icons={false}
              onChange={() => {
                setMakeDefault(!makeDefault);
              }}
            />
          </div>

          <button onClick={() => uploadPayID()} className="doneButton">
            Done
          </button>
        </div>
      )}
    </div>
  );
};

export default Step3;
