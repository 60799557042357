import React, { useCallback, useEffect, useState } from "react";
import { PaymentMethod, StepProps } from "../types";
import { API_URL } from "../../../utils/constants";
import axios, { AxiosResponse } from "axios";
import { toast } from "sonner";
import { useDialog } from "../../../context/DialogContext";

interface payoneerId {
  fontWeight: string | number;
  fontSize: string;
}

const Step1: React.FC<StepProps> = ({ setCurrentStep, setSelectedItem, values, updateValues }) => {
  const { openDialog, closeDialog } = useDialog();
  //centralized the token value
  const getAuthToken = () => localStorage.getItem("token");

  // centralized the Axios config aswell
  const getAxiosConfig = (token: string | null) => ({
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const fetchPayId = useCallback(async () => {
    const token = getAuthToken();
    updateValues({ ...values, loading: true });
    const url = `${API_URL}/payment-method`;
    try {
      const response: AxiosResponse = await axios.get<PaymentMethod[]>(url, getAxiosConfig(token));
      const result = response.data;

      result
        ? updateValues({
            ...values,
            loading: false,
            data: result,
          })
        : updateValues({
            ...values,
            loading: false,
            error: "Something went wrong",
          });
    } catch (err: any) {
      updateValues({
        ...values,
        loading: false,
        error: err.message ?? "Something went wrong",
      });
    }
  }, []);

  const deletePayID = useCallback(async (id: string) => {
    const token = getAuthToken();
    updateValues({ ...values, loading: true });
    console.log(values?.loading);

    const url = `${API_URL}/payment-method/${id}`;

    try {
      const response: AxiosResponse = await axios.delete<string>(url, getAxiosConfig(token));
      const result = response.data;
      if (result) {
        toast.success("Payoneer ID has been deleted successfully", {
          richColors: true,
        });
        closeDialog();
        updateValues({ ...values, loading: false, error: "" });
        fetchPayId();
      } else {
        toast.error(result.message ?? "Something went wrong", {
          richColors: true,
        });
        updateValues({ ...values, loading: false, error: "" });
      }
    } catch (err: any) {
      updateValues({ ...values, loading: false, error: "" });
      toast.error(err.message ?? "Something went wrong", {
        richColors: true,
      });
    }
  }, []);

  useEffect(() => {
    fetchPayId();
  }, []);

  const confirmDelete = (id: string, payoneerId: string) => {
    openDialog({
      dialogType: "Delete",
      title: "Delete",
      content: (
        <div>
          <p>Are you sure you want to delete this ID</p>
          <p style={IdTitle}>{payoneerId}</p>
        </div>
      ),
      loading: values.loading,
      isConfirm: () => {
        deletePayID(id);
      },
    });
  };

  const IdTitle: React.CSSProperties = {
    fontWeight: "bold",
    color: "#000000",
  };

  return values.loading ? (
    <p>Loading</p>
  ) : (
    <div data-test="body">
      <div className="walletContentContainer">
        <p className="wallet-title">Set withdrawal method</p>
        <button className="createBtnAdd" data-test="methodBtn" onClick={() => setCurrentStep(2)}>
          + add method
        </button>
      </div>
      {values.data.length < 1 ? (
        <p className="title">You are yet to add a payment method</p>
      ) : (
        values?.data?.map((item, index) => {
          return (
            <div className="payoneerContainer" key={index}>
              <div>
                <p className="payoneer">{item.type}</p>
                <p className="account" data-test="account">
                  {item.details.payoneerId}
                </p>
                {item.isPrimary ? <p className="default">default</p> : null}
              </div>
              <div className="editDelete">
                <p
                  onClick={() => {
                    setSelectedItem(item);
                    setCurrentStep(4);
                  }}
                  className="edit"
                >
                  edit
                </p>
                <p
                  onClick={() => confirmDelete(item.id, item.details.payoneerId)}
                  className="delete"
                >
                  delete
                </p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Step1;
