import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import styles from "../styles/dialog.module.scss";
import Button from "../components/Button";
import { useLocation } from "react-router-dom";

interface dialogType {
  dialogType: string;
  title: string;
  content: ReactNode;
  loading: boolean;
  isConfirm: () => void;
}

interface dialogContextType {
  openDialog: (config: dialogType) => void;
  closeDialog: () => void;
}

interface dialogProps {
  children: ReactNode;
}

export const DialogContext = createContext<dialogContextType | null>(null);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

export const DialogProvider: React.FC<dialogProps> = ({ children }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<dialogType | null>({
    dialogType: "",
    title: "",
    content: "",
    loading: false,
    isConfirm: () => {},
  });

  const openDialog = (config: dialogType) => {
    setIsOpen(true);
    setDialogData(config);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDialogData(null);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);
  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      <div className={styles.dialogModal}>
        {isOpen && <div className={styles.dOverlay}></div>}
        {children}
        {isOpen && (
          <div className={styles.dialogBox}>
            <div className={styles.boxed}>
              <h1 className={styles.dHead}>{dialogData?.title}</h1>
              <div className={styles.dMesa}>{dialogData?.content}</div>

              <div className={styles.btnBox}>
                <Button className={styles.dBtn} variant="neutral" onClick={closeDialog}>
                  Close
                </Button>
                <Button
                  className={`${styles.dBtn} ${styles.submits}`}
                  variant="neutral"
                  onClick={dialogData?.isConfirm}
                  isLoading={dialogData?.loading}
                >
                  {dialogData?.dialogType}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DialogContext.Provider>
  );
};
